<template>
  <div>
    <div>
      测试Echarts
    </div>
<!--    表格测试-->
    <div>
      <div id="myEcharts" style="width: 100%;height: 500px">

      </div>
    </div>

  </div>
</template>

<script>
import {Decrypt, Encrypt} from "@/utils/secret";

export default {
  name: "testecharts",
  data(){
    return{

    }
  },
  created() {
    console.log(Encrypt('915bec83b4b3c87cdca9dcf58a68e01c'));
    console.log(Decrypt(Encrypt('915bec83b4b3c87cdca9dcf58a68e01c')))
  },
  mounted() {
    this.testEcharts();
  },
  methods:{
    testEcharts(){

      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myEcharts'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      this.axios.post('http://localhost:8087/gravelCake/getdatacake', (response) => {
        console.log(response)
        if (response.obj.length !== 0){
          myChart.hideLoading();    //隐藏加载动画
          for (let i = 0; i < response.obj.length; i++){
            x.push(response.obj[i].name);
            y.push(response.obj[i].value)
          }

          // 绘制图表
          myChart.setOption({
            title: {
              text: ''
            },
            tooltip: {},
            xAxis: {
              data: x
            },
            yAxis: {},
            series: [
              {
                name: '销量',
                type: 'pie',
                data: y
              }
            ]
          });
        }

      },{
        gravelname: 1
      })
    }
  }
}
</script>

<style scoped>

</style>